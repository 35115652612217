<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-12
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.cargo_storage") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-select
              class="w-300"
              size="mini"
              v-model="filterForm.val"
              :placeholder="$t('message.branch')"
              multiple
            >
              <el-option
                v-for="item in branches"
                :key="item.name + item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            >
            </el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            >
            </el-date-picker>
          </div>
        </div>
      </div>

      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.branch"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.tenant"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.dogNamber"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.branch.show"></th>
            <th v-if="columns.tenant.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.tenant"
                :placeholder="columns.tenant.title"
              ></el-input>
            </th>
            <th v-if="columns.dogNamber.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.dogNamber"
                :placeholder="columns.dogNamber.title"
              ></el-input>
            </th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="(product, index) in list"
            :key="index + 'n'"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ product.product_id }}</td>
            <td v-if="columns.branch.show">{{ product.branch.name }}</td>
            <td v-if="columns.tenant.show">{{ product.product.name }}</td>
            <td v-if="columns.dogNamber.show">{{ product.total }}</td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "neededPills",
  data() {
    return {
      vall: "",
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "treatmentProductList/list",
      columns: "treatmentProductList/columns",
      pagination: "treatmentProductList/pagination",
      filter: "treatmentProductList/filter",
      sort: "treatmentProductList/sort",
      branches: "branches/inventory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    if (this.branches && this.branches.length === 0) await this.getInventory();
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        if (newVal.val.length == 0 && newVal.search != "") {
          this.loadingData = true;
          this.updateList(newVal)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        }
        if (newVal.val.length != 0 && newVal.search != "") {
          this.loadingData = true;
          this.updateList(newVal)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        }
        if (newVal.val.length == 0) {
          this.empty();
        } else {
          this.loadingData = true;
          this.updateList(newVal)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "treatmentProductList/showList",
      getInventory: "branches/inventory",
      updateSort: "treatmentProductList/updateSort",
      updateFilter: "treatmentProductList/updateFilter",
      updateColumn: "treatmentProductList/updateColumn",
      updatePagination: "treatmentProductList/updatePagination",
      editModel: "treatmentProductList/show",
      empty: "treatmentProductList/empty",
      delete: "treatmentProductList/destroy",
      refreshData: "treatmentProductList/refreshData",
    }),

    fetchData() {
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(this.filterForm)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
  components: {},
};
</script>